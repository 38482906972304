import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DropdownPosition } from '@ng-select/ng-select';
export { DropdownPosition };
export interface ISelectData {
  label: string;
  icon?: string;
  [key: string]: number | string | boolean | null | undefined;
}

@Component({
  selector: 'gtin-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent<T extends ISelectData> {
  @Input() dropDownPosition: DropdownPosition = 'auto';
  @Input() selectedValue: T | undefined;
  @Input() placeholder: string;
  @Input() selectData: T[];
  @Input() readonly: boolean = false;
  @Input() searchable: boolean = false;
  @Input() loading: boolean = false;
  @Input() label: string = 'label';
  @Input() clearable = false;
  @Input() disabled = false;
  @Input() appendTo: string;
  // eslint-disable-next-line
  @Input() searchFn: ((term: string, item: any) => boolean) | null =
    this.searchFnDefault;
  @Output() selectChange: EventEmitter<T | undefined> = new EventEmitter<
    T | undefined
  >();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  changeValue() {
    if (this.selectedValue == null) this.selectedValue = undefined;
    this.selectChange.emit(this.selectedValue);
  }
  // eslint-disable-next-line
  searchValue(ev: { term: string; items: any[] }) {
    this.search.emit(ev.term);
  }
  searchFnDefault(/*term: string, item: any*/): boolean {
    return true;
  }
  open(): void {
    if (this.searchable) this.search.emit('');
  }
}
