import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  public async copyToClipboard(data: string): Promise<boolean> {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(data);
      return true;
    } else {
      return this.copyFallback(data);
    }
  }

  private copyFallback(msg: string): boolean {
    // navigator.clipboard require secure connection
    const textArea = document.createElement('textarea');
    textArea.value = msg;
    let result = false;
    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      result = true;
    } finally {
      document.body.removeChild(textArea);
    }
    return result;
  }
}
