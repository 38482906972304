<pagination-template
  *ngIf="items"
  class="pagination"
  #p="paginationApi"
  [maxSize]="maxSize"
  (pageChange)="pageChanged($event)"
  [id]="id"
>
  <div class="pagination__row">
    <div *ngIf="sortByOptions" class="pagination__column">
      <gtin-select
        (selectChange)="sortByChange($event)"
        [selectData]="sortByOptions"
        class="select-gtin-pagination"
        [selectedValue]="sortByOptions[0]"
      ></gtin-select>
    </div>
    <div
      *ngIf="pageSizeOptions && pageSizeOptions.length > 0"
      class="pagination__column"
    >
      <gtin-select
        (selectChange)="pageSizeChange($event)"
        [selectData]="pageSizeOptions"
        class="select-gtin-pagination"
        [selectedValue]="pageSizeOptions[0]"
      ></gtin-select>
    </div>
    <ng-container>
      <div class="pagination__column">
        <div>
          <span class="pagination__text page">Page</span>
          <span class="pagination__label">{{ p.getCurrent() }}</span>
          <span class="pagination__text of">of</span>
          <span class="pagination__length">{{ p.pages.length }}</span>
        </div>
      </div>
      <div class="pagination__column">
        <div
          class="pagination-arrow-prev pagination-arrow"
          [class.disabled]="p.isFirstPage()"
        >
          <div (click)="!p.isFirstPage() && p.previous()">
            <svg
              class="pagination-arrow-svg"
              width="24"
              height="29"
              viewBox="0 0 24 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_340_840)">
                <rect
                  x="24"
                  y="29"
                  width="24"
                  height="29"
                  rx="10"
                  transform="rotate(180 24 29)"
                  fill="#358DD1"
                />
                <path
                  d="M6.64 14.368L16.36 9.292L16.36 11.872C16.36 12.056 16.312 12.224 16.216 12.376C16.12 12.528 15.968 12.66 15.76 12.772L12.352 14.62C12.152 14.732 11.936 14.82 11.704 14.884C11.464 14.956 11.216 15.024 10.96 15.088C11.216 15.152 11.464 15.22 11.704 15.292C11.936 15.372 12.152 15.468 12.352 15.58L15.76 17.416C15.968 17.528 16.12 17.66 16.216 17.812C16.312 17.972 16.36 18.144 16.36 18.328L16.36 20.908L6.64 15.82L6.64 14.368Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_340_840">
                  <rect
                    x="24"
                    y="29"
                    width="24"
                    height="29"
                    rx="10"
                    transform="rotate(180 24 29)"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div
          class="pagination-arrow-next pagination-arrow"
          [class.disabled]="p.isLastPage()"
        >
          <div (click)="!p.isLastPage() && p.next()">
            <svg
              class="pagination-arrow-svg"
              width="24"
              height="29"
              viewBox="0 0 24 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_340_838)">
                <rect width="24" height="29" rx="10" fill="#358DD1" />
                <path
                  d="M17.36 14.632L7.64 19.708V17.128C7.64 16.944 7.688 16.776 7.784 16.624C7.88 16.472 8.032 16.34 8.24 16.228L11.648 14.38C11.848 14.268 12.064 14.18 12.296 14.116C12.536 14.044 12.784 13.976 13.04 13.912C12.784 13.848 12.536 13.78 12.296 13.708C12.064 13.628 11.848 13.532 11.648 13.42L8.24 11.584C8.032 11.472 7.88 11.34 7.784 11.188C7.688 11.028 7.64 10.856 7.64 10.672V8.092L17.36 13.18V14.632Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_340_838">
                  <rect width="24" height="29" rx="10" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</pagination-template>
