import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapTo, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private http: HttpClient) {}

  public saveFile(blob: Blob | null, targetFilename?: string): void {
    if (blob) {
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = window.URL.createObjectURL(blob);
      if (targetFilename) anchor.setAttribute('download', targetFilename);
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      document.body.removeChild(anchor);
    }
  }

  public downloadFile(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }

  public downloadFileViaPost(
    url: string,
    body: any
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(url, body, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  public downloadNSaveFile(
    url: string,
    targetFilename?: string
  ): Observable<null> {
    return this.downloadFile(url).pipe(
      tap((e) => {
        if (targetFilename === undefined) {
          const contentDisposition = e.headers.get('Content-Disposition');
          if (contentDisposition) {
            targetFilename = contentDisposition
              .split(';')[1]
              .trim()
              .split('=')[1]
              .replace(/"/g, '');
          }
        }
        this.saveFile(e.body, targetFilename);
      }),
      mapTo(null)
    );
  }

  public downloadNSaveFileViaPost(
    url: string,
    body: any,
    targetFilename?: string
  ): Observable<null> {
    return this.downloadFileViaPost(url, body).pipe(
      tap((e) => {
        if (targetFilename === undefined) {
          const contentDisposition = e.headers.get('Content-Disposition');
          if (contentDisposition) {
            targetFilename = contentDisposition
              .split(';')[1]
              .trim()
              .split('=')[1]
              .replace(/"/g, '');
          }
        }
        this.saveFile(e.body, targetFilename);
      }),
      mapTo(null)
    );
  }
}
