import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APIEndpoints } from './services';
import { SearchService } from './services/search.service';

@NgModule({
  imports: [CommonModule],
  providers: [APIEndpoints, SearchService],
})
export class CommonDataModule {}
