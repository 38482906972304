import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { UserRole } from '@shared/data/common';

@Injectable({
  providedIn: 'root',
})
export class ClientGuard implements CanLoad, CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canLoad(/*route: Route*/): boolean {
    return this._isClient();
  }
  canActivate(): //route: ActivatedRouteSnapshot,
  //state: RouterStateSnapshot
  boolean {
    return this._isClient();
  }
  private _isClient(): boolean {
    const userRole = this.authService.userInfo().userRole;
    if (userRole === UserRole.Unauthorized) {
      this.router.navigate(['/auth']);
      return false;
    }
    return true;
  }
}
