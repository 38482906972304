<div class="header gray" #header>
  <div class="container-header">
    <div class="wrapper">
      <div class="logo">
        <a routerLink="/"
          ><img src="/assets/images/main-page/icons/logo.svg" alt="logo"
        /></a>
      </div>
      <div class="group-wrapper">
        <div class="form-group search">
          <input
            class="form-control readonly"
            type="text"
            placeholder="Enter 12-14 digit GTIN"
            readonly
            (focus)="focusSearch()"
            [(ngModel)]="query"
          />
          <img
            src="/assets/images/main-page/icons/search.svg"
            alt="search"
            (click)="focusSearch()"
          />
        </div>
        <div class="login-button-wrp">
          <a routerLink="/auth" class="login-button">
            Log in
            <img
              src="/assets/images/main-page/icons/login-profile.svg"
              alt="login-profile"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="search-section">
  <div class="container">
    <div class="block-wrapper">
      <h2>Search GTIN</h2>
      <div class="form-wrapper">
        <input
          #searchInput
          class="form-control"
          type="text"
          placeholder="Enter GTIN"
          gtinOnlyNumber="digits"
          maxlength="14"
          minlength="12"
          [(ngModel)]="query"
          (keydown.enter)="search()"
        />
        <button
          class="btn blue-2"
          (click)="search()"
          [disabled]="loading || !isValid()"
        >
          Search
        </button>
        <div class="error-message" *ngIf="!isValid() || errMsg">
          {{ errMsg }}
        </div>
      </div>
    </div>
  </div>
</section>
<section class="product-info">
  <div class="container">
    <div class="block-wrapper" *ngIf="result; else noResult">
      <div class="part-wrapper">
        <div class="left-part">
          <h3>GTIN Information</h3>
          <div class="table-block">
            <div class="table-row">
              <div>GTIN</div>
              <div>{{ result.gtin }}</div>
            </div>
            <div class="table-row">
              <div>Description</div>
              <div>{{ result.description }}</div>
            </div>
            <div class="table-row">
              <div>Sku</div>
              <div>{{ result.sku }}</div>
            </div>
            <div class="table-row">
              <div>Packaging Level</div>
              <div>{{ result.packagingLevel }}</div>
            </div>
            <div class="table-row">
              <div>Brand</div>
              <div>{{ result.brand }}</div>
            </div>
          </div>
        </div>
        <div class="right-part">
          <div class="product-image" *ngIf="result.imageURL && showProdImg">
            <h4>Product Image</h4>
            <a [href]="result.imageURL" target="_blank"
              ><img
                [src]="result.imageURL"
                alt=""
                (error)="showProdImg = false"
            /></a>
          </div>
        </div>
      </div>
      <div class="part-wrapper">
        <div class="left-part">
          <h3>GS1 Company Prefix</h3>
          <div class="table-block">
            <div class="table-row">
              <div>Party Name</div>
              <div>{{ result.partyName }}</div>
            </div>
            <div class="table-row">
              <div>GS1 Prefix</div>
              <div>{{ result.prefix }}</div>
            </div>
          </div>
        </div>
        <div class="right-part">
          <div class="certificate" *ngIf="cert && showCert">
            <h4>GS1 Certificate</h4>
            <img
              *ngIf="!isCertPdf"
              [src]="cert"
              alt="PrefixCertificate"
              (click)="showCertModal()"
              (error)="showCert = false"
            />
            <pdf-viewer
              *ngIf="isCertPdf"
              [src]="cert"
              [render-text]="false"
              [original-size]="false"
              [page]="1"
              [show-all]="false"
              [autoresize]="true"
              (error)="showCert = false"
              (click)="showCertModal()"
            ></pdf-viewer>
          </div>
        </div>
      </div>
      <div class="part-wrapper">
        <div class="left-part">
          <h3>Results Information</h3>
          <div class="table-block">
            <div class="table-row">
              <div>Provider</div>
              <div>{{ result.provider }}</div>
            </div>
            <div class="table-row">
              <div>Query Date</div>
              <div>
                {{ result.queryDate | date: 'yyyy-MM-dd hh:mm:ss UTC' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noResult>
      <div class="no-results" *ngIf="!loading">
        {{
          searchedGTIN
            ? 'No results for GTIN Search ‘' + searchedGTIN + '’'
            : 'No results.'
        }}
      </div>
    </ng-template>
  </div>
  <gtin-custom-loader [isVisible]="loading"></gtin-custom-loader>
</section>
<section class="global-data">
  <div class="container">
    <div class="wrapper">
      <div class="img-wrapper">
        <img src="/assets/images/main-page/icons/logo-big.svg" alt="logo" />
      </div>
      <div class="block-wrapper">
        <h3>Global <b>UPC</b> Product data management registry</h3>
        <a
          class="link-form"
          [href]="environment.MVC_ROOT + '/buy-upcs/support-subscriptions'"
          >Create Account</a
        >
      </div>
      <button class="arrow-up" (click)="scrollUp()">
        <img src="/assets/images/main-page/icons/arr-up.svg" alt="arr-up" />
      </button>
    </div>
  </div>
</section>
<gtin-callus></gtin-callus>
