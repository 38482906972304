import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EndpointBase, IParams } from './api-endpoints.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { IAPIResponse } from '@shared/utils';
import { IGTINSearchResult } from '../models/search.model';

@Injectable()
export class SearchService {
  constructor(private http: HttpClient) {}

  SearchGTIN(
    gtin: string,
    token: string,
    params?: IParams,
    skipLoader: boolean = false,
    suppressErrors: boolean = false
  ): Observable<IGTINSearchResult> {
    return this.http
      .post<IAPIResponse<IGTINSearchResult>>(
        environment.SEARCH_BASE,
        { gtin, token },
        {
          params,
          context: EndpointBase.context({ skipLoader, suppressErrors }),
        }
      )
      .pipe(map((resp) => resp.data));
  }
}
