import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelectModule } from '@shared/components';
import {
  PaginationComponent,
  PageSizeOption,
  SortingOption,
} from './pagination.component';

@NgModule({
  imports: [CommonModule, NgxPaginationModule, SelectModule],
  declarations: [PaginationComponent],
  exports: [PaginationComponent, NgxPaginationModule],
})
export class PaginationModule {}
export { PageSizeOption, SortingOption };
