import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@shared/utils';
import { HostListener } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'gtin-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent extends BaseComponent {
  isGray: boolean = false;
  valChanged: boolean = false;
  environment = environment;
  query = '';
  constructor(private router: Router) {
    super();
  }

  @HostListener('window:scroll', []) onWindowScroll() {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const curVal = verticalOffset > 70;
    this.valChanged = this.isGray !== curVal;
    if (this.valChanged) {
      this.isGray = curVal;
    }
  }

  search() {
    this.router.navigate(['search', this.query]);
  }
}
