import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, of, finalize } from 'rxjs';
import { LocalPreferences } from '@core/utils';
import { IAuth, UserRole } from '@shared/data/common';
import { APIEndpoints } from '@shared/data/common/services';

export interface UserInfo {
  readonly userRole: UserRole;
  readonly userId: string | undefined;
  readonly accountIdOverride: number | null;
  readonly email: string | undefined;
  readonly phone: string | undefined;
  readonly fullName: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userInfo: UserInfo = {
    userRole: UserRole.Unauthorized,
    accountIdOverride: null,
    userId: undefined,
    phone: undefined,
    email: undefined,
    fullName: 'Unknown',
  };

  private _mustSetPassword: boolean = false;

  constructor(private API: APIEndpoints) {
    const tokenData = this.readToken(
      LocalPreferences.token,
      LocalPreferences.accountId
    );
    if (tokenData === undefined) this.resetAuthData();
    else this._userInfo = tokenData;
    //this.resetAccountOverride();
  }

  public userInfo(): UserInfo {
    return this._userInfo;
  }

  public isViewer(): boolean {
    return this._userInfo.userRole == UserRole.Viewer;
  }

  public isEditor(): boolean {
    return this._userInfo.userRole == UserRole.Editor;
  }

  public isAccountOwner(): boolean {
    return (
      this._userInfo.userRole == UserRole.AccountOwner ||
      this._userInfo.userRole == UserRole.Administrator
    );
  }

  public isAdmin(): boolean {
    return this._userInfo.userRole == UserRole.Administrator;
  }

  public mustSetPassword(): boolean {
    return this._mustSetPassword;
  }

  public passwordWasSet(): boolean {
    return (this._mustSetPassword = false);
  }

  public overrideAccount(accId: number): void {
    if (this._userInfo.userRole === UserRole.Administrator) {
      this._userInfo = {
        userRole: this._userInfo.userRole,
        accountIdOverride: accId,
        fullName: this._userInfo.fullName,
        phone: this._userInfo.phone,
        email: this._userInfo.email,
        userId: this._userInfo.userId,
      };
      LocalPreferences.accountId = accId;
    }
  }

  public resetAccountOverride(): void {
    LocalPreferences.accountId = null;
    const tokenData = this.readToken(LocalPreferences.token);
    if (tokenData) this._userInfo = tokenData;
  }

  public getToken(): string | null | undefined {
    return LocalPreferences.token;
  }

  public hasRefreshToken(): boolean {
    const rtoken = LocalPreferences.refreshToken;
    return rtoken !== null && rtoken !== undefined && rtoken !== '';
  }

  public resetAuthData(): void {
    this._userInfo = {
      userRole: UserRole.Unauthorized,
      accountIdOverride: null,
      userId: undefined,
      email: undefined,
      phone: undefined,
      fullName: 'Unknown',
    };
    LocalPreferences.token = null;
    LocalPreferences.refreshToken = null;
    LocalPreferences.accountId = null;
  }

  public logout(suppressErrors: boolean = false): Observable<void> {
    const refreshToken = LocalPreferences.refreshToken;
    return this.API.Auth.Logout(refreshToken, {}, suppressErrors).pipe(
      finalize(() => this.resetAuthData()),
      catchError(() => of(void 0)) //ignore any error
    );
  }

  public login(
    userEmail: string,
    password: string,
    suppressErrors: boolean = false
  ): Observable<boolean> {
    this.resetAuthData();
    return this.API.Auth.Login(userEmail, password, {}, suppressErrors).pipe(
      tap((resp) => this._processLoginResult(resp.data)),
      map((): boolean => true)
    );
  }

  public loginByToken(
    token: string,
    suppressErrors: boolean = false
  ): Observable<boolean> {
    this.resetAuthData();
    return this.API.Auth.LoginByToken(token, {}, suppressErrors).pipe(
      tap((resp) => this._processLoginResult(resp.data)),
      map((): boolean => true)
    );
  }

  public confirmEmail(
    userId: string,
    confirmationToken: string,
    suppressErrors: boolean = false
  ): Observable<boolean> {
    this.resetAuthData();
    return this.API.Auth.ConfirmEmail(
      userId,
      confirmationToken,
      {},
      suppressErrors
    ).pipe(
      tap((resp) => this._processLoginResult(resp.data)),
      map((resp): boolean => {
        this._mustSetPassword = resp.data.mustSetPassword ?? false;
        return true;
      })
    );
  }

  public resetPassword(
    userId: string,
    token: string,
    password: string,
    suppressErrors: boolean = false
  ): Observable<boolean> {
    this.resetAuthData();
    return this.API.Auth.ResetPassword(
      userId,
      token,
      password,
      {},
      suppressErrors
    ).pipe(map((): boolean => true));
  }

  public refreshToken(
    suppressErrors: boolean = false
  ): Observable<string | null> {
    const token = LocalPreferences.token;
    const refreshToken = LocalPreferences.refreshToken;
    return this.API.Auth.RefreshToken(
      token,
      refreshToken,
      {},
      suppressErrors
    ).pipe(
      map((resp): string | null => {
        if (this._processLoginResult(resp.data)) return LocalPreferences.token;
        return null;
      })
    );
  }

  sendResetPassword(
    email: string,
    suppressErrors: boolean = false
  ): Observable<boolean> {
    return this.API.Auth.SendResetPassword(email, {}, suppressErrors).pipe(
      map((e) => e.data)
    );
  }

  // eslint-disable-next-line
  private static _parserJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  public validateAuthData() {
    const tokenData = this.readToken(LocalPreferences.token);
    return tokenData?.userId == this._userInfo.userId;
  }

  private readToken(
    token: string | undefined | null,
    accId: number | null = null
  ) {
    if (token) {
      const tokenData = AuthService._parserJwt(token);
      if (tokenData) {
        return {
          userRole: tokenData.role,
          accountIdOverride: accId,
          fullName: tokenData.given_name,
          userId: tokenData.nameid,
          phone: tokenData.phone,
          email: tokenData.email,
        };
      }
    }
    return undefined;
  }

  private _processLoginResult(resp: IAuth): boolean {
    const tokenData = this.readToken(resp.token);
    if (tokenData !== undefined) {
      this._userInfo = tokenData;
      LocalPreferences.token = resp.token;
      LocalPreferences.refreshToken = resp.refreshToken;
      return true;
    }
    return false;
  }
}
