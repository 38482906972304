import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@core/guards/admin.guard';
import { ClientGuard } from '@core/guards/client.guard';
import { MainPageComponent } from '@core/pages/main-page/main-page.component';
import { SearchPageComponent } from '@core/pages/search/search.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('app/authorize/authorize.module').then((m) => m.AuthorizeModule),
  },
  {
    path: '',
    // canActivate: [RoleGuard],
    // children: [],
    component: MainPageComponent,
  },
  {
    path: 'search/:query',
    component: SearchPageComponent,
  },
  {
    path: 'search',
    component: SearchPageComponent,
  },
  {
    path: 'user',
    canLoad: [ClientGuard],
    canActivate: [ClientGuard],
    loadChildren: () =>
      import('app/client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'admin',
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('app/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
