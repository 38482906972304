<section class="call-us">
  <div class="container">
    <div class="wrapper">
      <div class="form">
        <h2>Get in touch</h2>
        <form [formGroup]="form">
          <div class="form-group">
            <input
              class="form-control"
              name="name"
              type="text"
              placeholder="name"
              formControlName="name"
            />
            <div
              class="error-message"
              *ngIf="
                form.controls['name'].touched && form.controls['name']?.invalid
              "
            >
              Please enter the name
            </div>
          </div>
          <div class="form-group">
            <input
              class="form-control"
              name="email"
              type="email"
              placeholder="email"
              formControlName="email"
            />
            <div
              class="error-message"
              *ngIf="
                form.controls['email'].touched &&
                form.controls['email']?.invalid
              "
            >
              Please enter valid email
            </div>
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              name="message"
              placeholder="message"
              formControlName="message"
            ></textarea>
            <div
              class="error-message"
              *ngIf="
                form.controls['message'].touched &&
                form.controls['message']?.invalid
              "
            >
              Please enter message
            </div>
          </div>
          <button class="btn blue-2" (click)="send()">Send</button>
        </form>
      </div>
      <div class="form-desc">
        <h2>We are here to help!</h2>
        <a class="phone" href="tel:+18006620701"
          >Call Us: <br />
          888-540-6885</a
        >
        <div class="location">
          <div>Bar Code Graphics, Inc.</div>
          <div>333 N. Michigan Avenue Suite #1114</div>
          <div>Chicago, IL 60601</div>
          <div>888-540-6885</div>
        </div>
        <img src="/assets/images/main-page/icons/logo-middle.svg" alt="logo" />
        <a [href]="environment.MVC_ROOT"
          ><img
            src="/assets/images/main-page/icons/barcode-us.svg"
            alt="barcode-us.com"
        /></a>
      </div>
    </div>
  </div>
</section>
