import { FactoryProvider } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const PREFIX = `${window.location.origin}/assets/i18n/`;
const SUFFIX = '.json';

function httpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, PREFIX, SUFFIX);
}

export const TranslateLoaderProvider: FactoryProvider = {
  provide: TranslateLoader,
  useFactory: httpLoaderFactory,
  deps: [HttpClient],
};
