import { Directive, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[gtinOnlyNumber]',
})
export class OnlyNumberDirective implements OnInit {
  private regex: RegExp; // = new RegExp(/^[0-9]+$/g);
  @Input() gtinOnlyNumber:
    | 'integer'
    | 'positive_integer'
    | 'float'
    | 'positive_float'
    | 'digits'
    | '';

  ngOnInit() {
    switch (this.gtinOnlyNumber) {
      case 'digits':
        this.regex = new RegExp(/^[0-9]+$/g);
        break;
      case 'integer':
        this.regex = new RegExp(/^[0-9-+]+$/g);
        break;
      case 'positive_integer':
        this.regex = new RegExp(/^[0-9+]+$/g);
        break;
      case 'positive_float':
        this.regex = new RegExp(/^[0-9+.]+$/g);
        break;
      case 'float':
      default:
        this.regex = new RegExp(/^[0-9-+.]+$/g);
        break;
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    if (
      ['Delete', 'Backspace', 'Tab', 'Esc', 'Enter'].indexOf(event.key) !==
        -1 ||
      (event.code === 'KeyA' && (event.ctrlKey || event.metaKey)) ||
      (event.code === 'KeyC' && (event.ctrlKey || event.metaKey)) ||
      (event.code === 'KeyV' && (event.ctrlKey || event.metaKey)) ||
      (event.code === 'KeyX' && (event.ctrlKey || event.metaKey)) ||
      event.code === 'Home' ||
      event.code === 'End' ||
      event.code === 'ArrowLeft' ||
      event.code === 'ArrowRight' ||
      event.code === 'ArrowDown' ||
      event.code === 'ArrowUp'
    ) {
      return;
    }

    if (event.shiftKey || !event.key.match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onInput(event: ClipboardEvent): void {
    if (event.clipboardData) {
      const next = event.clipboardData.getData('text/plain');

      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    if (event.dataTransfer) {
      const next = event.dataTransfer.getData('text/plain');

      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }
}
