<div class="header" [class.gray]="isGray">
  <div class="container-header">
    <div class="wrapper">
      <div class="logo">
        <a routerLink="/"
          ><img src="/assets/images/main-page/icons/logo.svg" alt="logo"
        /></a>
      </div>
      <div class="group-wrapper">
        <div class="form-group search">
          <label>Look up GTIN</label>
          <input
            class="form-control"
            type="text"
            placeholder="Enter 12-14 digit GTIN"
            gtinOnlyNumber="digits"
            maxlength="14"
            minlength="12"
            [(ngModel)]="query"
            (keydown.enter)="search()"
          />
          <img
            src="/assets/images/main-page/icons/search.svg"
            alt="search"
            (click)="search()"
          />
        </div>
        <div class="login-button-wrp">
          <a routerLink="/auth" class="login-button">
            Log in
            <img
              src="/assets/images/main-page/icons/login-profile.svg"
              alt="login-profile"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="main-screen" [class.gray]="isGray">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 text-block">
        <h2>
          The brand managed global repository for <b>GTIN</b> product data and
          images
        </h2>
        <a
          class="link-form"
          [href]="environment.MVC_ROOT + '/buy-upcs/support-subscriptions'"
          >Create a New Account
          <img
            src="/assets/images/main-page/icons/Profile-add.svg"
            alt="Profile-add"
        /></a>
      </div>
      <div class="col-0 col-md-6 image-wrapper">
        <img src="/assets/images/main-page/world.png" alt="" />
      </div>
    </div>
  </div>
</section>
<section class="support-program">
  <div class="container">
    <h2 class="section-title primary">
      GS1 Barcode Support Programs Include GTIN.cloud Subscription
    </h2>
    <img src="/assets/images/main-page/icons/main-img.svg" alt="main-img" />
    <div>
      <h3>Global UPC Product Data Management Portal</h3>
      <p>
        <b>GTIN.cloud</b> is the online resource which manufacturers upload and
        manage their UPC product data and images. The GTIN management function
        enables companies to assign and activate their GS1 GTINs, associate
        product data attributes, upload images, create digital .eps barcode
        files. All entered UPC product data is personally validated by an
        assigned GS1 barcode consultant before UPC are published. UPC product
        data can be exported to other data resources, such as GS1 Data Hub,
        Amazon and Google Merchant center.
      </p>
    </div>
  </div>
</section>
<section class="how-works">
  <div class="container">
    <h2 class="section-title">How It Works</h2>
    <div class="block-wrapper">
      <div class="text-block">
        <h3>Assign GTINs & Manage Product Data</h3>
        <p>
          As the central location for managing UPC (GTIN) assignments, the
          GTIN.cloud empowers companies to adhere to global identification
          standards and act as a storage mechanism for UPC assignments. In
          addition to providing UPC product data management, the corresponding
          case pack (GTIN-14) can also be created on GTIN.cloud. All entered
          data is accessible by dynamic and manual search queries.
        </p>
      </div>
      <div class="image-wrapper">
        <img
          src="/assets/images/main-page/icons/Rectangle-assign.svg"
          alt="Assign"
        />
      </div>
    </div>
    <div class="block-wrapper reverse">
      <div class="text-block">
        <h3>GTIN Data Validated By Our GS1 Barcode Consultants</h3>
        <p>
          Today's consumers demand accurate information and the UPC barcode is
          essentially the bridge connecting the physical product to digital
          world. Major retailers and online marketplaces, such as Amazon,
          emphasize the necessity for correctly formatted and trusted UPC
          product data. Prior to publishing, our GS1 Barcode Consultants
          personally validate entered product data for compliance and accuracy.
          Since GTINs can never be reused, it is important to do it right the
          first time.
        </p>
      </div>
      <div class="image-wrapper">
        <img
          src="/assets/images/main-page/icons/Rectangle-data.svg"
          alt="Assign"
        />
      </div>
    </div>
    <div class="block-wrapper">
      <div class="text-block">
        <h3>Export Data to Other Registries</h3>
        <p>
          Most every trading partner has a single directional registry where
          companies are required provide their GTIN data. Organizations ranging
          from Amazon to Google and even GS1 have tools companies can use to
          upload their data. Unfortunately, once the GTIN and GTIN-14 (case
          pack) data is entered, companies cannot share with other databases.
          The GTIN.cloud provides the flexibility to import and export all GTIN
          formats and is used as the single source repository.
        </p>
      </div>
      <div class="image-wrapper">
        <img
          src="/assets/images/main-page/icons/Rectangle-export.svg"
          alt="Assign"
        />
      </div>
    </div>
  </div>
</section>
<section class="attributes">
  <div class="container">
    <h2 class="section-title">Comprehensive Attributes for Each GTIN</h2>
    <div class="card-wrapper">
      <div class="card">
        <h5>Description Images</h5>
      </div>
      <div class="card">
        <h5>Dimensional Data</h5>
      </div>
      <div class="card">
        <h5>Product Categories</h5>
      </div>
      <div class="card">
        <h5>GS1 Company Prefix License Certificate</h5>
      </div>
      <div class="card">
        <h5>Manufacturers Website</h5>
      </div>
      <div class="card">
        <h5>Associated GTIN-14s</h5>
      </div>
    </div>
  </div>
</section>
<section class="global-data">
  <div class="container">
    <div class="wrapper">
      <div class="img-wrapper">
        <img src="/assets/images/main-page/icons/logo-big.svg" alt="logo" />
      </div>
      <div class="block-wrapper">
        <h3>Global <b>UPC</b> Product data management registry</h3>
        <a
          class="link-form"
          [href]="environment.MVC_ROOT + '/buy-upcs/support-subscriptions'"
          >Create Account</a
        >
      </div>
      <a class="arrow-up" href="#">
        <img src="/assets/images/main-page/icons/arr-up.svg" alt="arr-up" />
      </a>
    </div>
  </div>
</section>
<gtin-callus></gtin-callus>
