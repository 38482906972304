import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { UserRole } from '@shared/data/common';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanLoad, CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canLoad(/*route: Route*/): boolean {
    return this._isAdmin();
  }
  canActivate(): /*route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot*/
  boolean {
    return this._isAdmin();
  }
  private _isAdmin(): boolean {
    const userRole = this.authService.userInfo().userRole;
    if (userRole !== UserRole.Administrator) {
      this.router.navigate(['/auth']);
      return false;
    }
    return true;
  }
}
