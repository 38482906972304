import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IPaginatedData, IPagination, SortDirection } from '@shared/utils';
import { ISelectData } from '@shared/components/select/select.component';

export type PageSizeOption = ISelectData & { pageSize: number };
export type SortingOption = ISelectData & {
  sortby?: string;
  order?: SortDirection;
};

@Component({
  selector: 'gtin-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Output() pageChange: EventEmitter<IPagination> =
    new EventEmitter<IPagination>();
  @Input() set pagination(value: IPagination) {
    this._pagination = { ...value };
  }
  // eslint-disable-next-line
  @Input() items: IPaginatedData<any>;
  @Input() pageSizeOptions?: PageSizeOption[];
  @Input() sortByOptions?: SortingOption[];
  @Input() pageSize: PageSizeOption = { label: '', pageSize: 10 };
  @Input() sortBy: SortingOption;
  @Input() id = 'default';
  @Input() maxSize: number = 111;
  currentPage = 1;
  private _pagination: IPagination;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.pageSizeOptions &&
      this.pageSizeOptions &&
      this.pageSizeOptions.length > 0
    ) {
      this.pageSize = this.pageSizeOptions[0];
    }
    if (
      changes.sortByOptions &&
      this.sortByOptions &&
      this.sortByOptions.length > 0
    ) {
      this.sortBy = this.sortByOptions[0];
    }
  }

  pageChanged(page: number): void {
    this.currentPage = page;
    this.emitPagination();
  }

  pageSizeChange(data: PageSizeOption | undefined): void {
    if (data) {
      this.currentPage = 1;
      this.pageSize = data;
      this.emitPagination();
    }
  }

  sortByChange(data: SortingOption | undefined): void {
    if (data) {
      this.sortBy = data;
      this.emitPagination();
    }
  }

  private emitPagination(): void {
    this._pagination.page = this.currentPage;
    if (this.sortByOptions) {
      this._pagination.sortBy = this.sortBy.sortby;
      this._pagination.orderBy = this.sortBy.order;
    }
    this._pagination.pageSize = this.pageSize.pageSize;
    this.pageChange.emit(this._pagination);
  }
}
