export enum GtinStatusEnum {
  Cancelled = -10,
  IncompleteData = 0,
  FailedValidation = 10,
  PendingValidation = 20,
  ReadyToPublish = 30,
  Published = 40,
}

export enum GtinStatusEnumKeys {
  Cancelled = 'Cancelled',
  IncompleteData = 'IncompleteData',
  FailedValidation = 'FailedValidation',
  PendingValidation = 'PendingValidation',
  ReadyToPublish = 'ReadyToPublish',
  Published = 'Published',
}
