import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAPIException } from '@shared/utils';
import { environment } from '@env';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private translate: TranslateService,
    private notifyService: NotificationService
  ) {}
  // eslint-disable-next-line
  public onAppError(err: any): void {
    if (!err) return;
    if (typeof err === 'string') {
      console.log(err);
    } else if (err.message !== undefined) {
      console.log(err.message);
    } else console.log(err.toString());
  }

  public onAPIError(apiRes: IAPIException, suppress: boolean = false): void {
    let message: string | undefined = undefined;
    if (apiRes.errors && apiRes.errors?.length > 0)
      message = this.getErrorMessage(apiRes.errors[0].errorCode);
    if (message) {
      console.log('Error: ' + message);
      //notifier.error(translateService.instant(message));
    } else {
      message = apiRes.message;
      if (message) {
        console.log('Error: ' + message);
        if (!suppress) this.notifyService.apiErrorHandler(apiRes);
        if (apiRes.errors && !environment.production)
          console.log(apiRes.errors);
      } else console.log('Unknown or internal error');
    }
  }

  public onHttpError(
    error: HttpErrorResponse,
    suppress: boolean = false
  ): void {
    if (error.status === 0)
      this.onAPIError({
        success: false,
        message: `Network error`,
      });
    else if (error.status === 403)
      this.onAPIError({
        success: false,
        message: `Access Denied`,
      });
    else {
      const apiError = error.error as IAPIException;
      if (
        apiError !== null &&
        apiError.success !== undefined &&
        apiError.message !== undefined
      )
        this.onAPIError(apiError, suppress);
      else
        this.onAPIError(
          {
            success: false,
            message: `HTTP error ${error.status} "${error.message}"`,
          },
          suppress
        );
    }
  }

  private getErrorMessage(statusCode: number): string | undefined {
    const errorMessagesList: Map<number, string> = new Map([]);

    return errorMessagesList.get(statusCode);
  }
}
