import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, take, switchMap, filter } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { UserRole } from '@shared/data/common';
import { ErrorService } from '@core/services/error.service';
import { LoaderService } from '@core/services/loader.service';
import { SkipLoaderToken, SuppressErrorNotification } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class GlobalHTTPInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  // eslint-disable-next-line
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private loaderService: LoaderService,
    private authService: AuthService,
    private errorService: ErrorService,
    private router: Router
  ) {}

  intercept(
    // eslint-disable-next-line
    request: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line
  ): Observable<HttpEvent<any>> {
    const skipLoader = request.context.has(SkipLoaderToken);
    const suprressError = request.context.has(SuppressErrorNotification);
    if (!skipLoader) this.loaderService.inc();
    const userInfo = this.authService.userInfo();
    if (userInfo.userRole != UserRole.Unauthorized) {
      if (!this.authService.validateAuthData()) {
        this.loaderService.reset();
        // this.authService.resetAuthData();
        this.router.navigate(['auth', 'login']);
        return throwError(() => 'User logged out');
      }
      request = this.addTokenHeader(request, this.authService.getToken());
      request = this.addAccountHeader(request, userInfo.accountIdOverride);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !request.url.includes('/identity/login')) {
          return this.handle401Error(request, next);
        } else if (error.status === 400) {
          this.errorService.onAPIError(error.error, suprressError);
        } else if (error.status === 0) {
          this.errorService.onHttpError(error, suprressError);
          this.authService.resetAuthData();
          this.router.navigateByUrl('/');
        } else this.errorService.onHttpError(error, suprressError);
        if (!skipLoader) this.loaderService.dec();
        return throwError(() => error);
      }),
      finalize(() => {
        if (!skipLoader) this.loaderService.dec();
      })
    );
  }

  private handle401Error(
    // eslint-disable-next-line
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      if (this.authService.hasRefreshToken())
        return this.authService.refreshToken(true).pipe(
          switchMap((token: string | null) => {
            if (token === null) return throwError(() => 'Refresh failed');
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token);

            return next.handle(this.addTokenHeader(request, token));
          }),
          catchError((err) => {
            this.loaderService.reset();
            this.isRefreshing = false;
            this.authService.resetAuthData();
            this.router.navigate(['auth', 'login']);
            return throwError(() => err);
          })
        );
      else {
        this.loaderService.reset();
        this.isRefreshing = false;
        this.authService.resetAuthData();
        this.router.navigate(['auth', 'login']);
        return throwError(() => 'User logged out');
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(
    // eslint-disable-next-line
    request: HttpRequest<any>,
    token: string | null | undefined
  ) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }

  private addAccountHeader(
    // eslint-disable-next-line
    request: HttpRequest<any>,
    accountId: number | null
  ) {
    if (accountId)
      return request.clone({
        headers: request.headers.set('accountId', '' + accountId),
      });
    return request;
  }
}
