import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';

import {
  IAPIResponse,
  IPagination,
  SkipLoaderToken,
  SuppressErrorNotification,
} from '@shared/utils';
import { IAuth } from '..';

// eslint-disable-next-line
export type IParams = { [key: string]: any };

export class EndpointBase {
  constructor(protected _http: HttpClient) {}
  static context(
    params: { skipLoader?: boolean; suppressErrors?: boolean },
    ctx?: HttpContext
  ): HttpContext {
    if (ctx === undefined) ctx = new HttpContext();
    if (params.skipLoader) ctx.set(SkipLoaderToken, params.skipLoader);
    if (params.suppressErrors)
      ctx.set(SuppressErrorNotification, params.suppressErrors);
    return ctx;
  }
  public setPagination(params: IParams, pagination?: IPagination): IParams {
    if (pagination !== undefined) {
      for (const key in pagination) {
        if (pagination[key] !== undefined) params[key] = pagination[key];
      }
    }
    return params;
  }
}

@Injectable()
export class APIEndpoints extends EndpointBase {
  constructor(http: HttpClient) {
    super(http);
  }

  public readonly Auth = new (class extends EndpointBase {
    public readonly Logout = (
      refreshToken: string | null,
      params?: IParams,
      suppressErrors: boolean = false
    ) =>
      this._http.post<void>(
        environment.API_BASE + '/identity/logout',
        { refreshToken },
        { params, context: EndpointBase.context({ suppressErrors }) }
      );

    public readonly Login = (
      userEmail: string,
      password: string,
      params?: IParams,
      suppressErrors: boolean = false
    ) =>
      this._http.post<IAPIResponse<IAuth>>(
        environment.API_BASE + '/identity/login',
        {
          userEmail,
          password,
        },
        { params, context: EndpointBase.context({ suppressErrors }) }
      );

    public readonly LoginByToken = (
      token: string,
      params?: IParams,
      suppressErrors: boolean = false
    ) =>
      this._http.get<IAPIResponse<IAuth>>(
        environment.API_BASE + '/identity/loginByToken',
        {
          params: { token, ...params },
          context: EndpointBase.context({ suppressErrors }),
        }
      );

    public readonly ConfirmEmail = (
      userId: string,
      confirmationToken: string,
      params?: IParams,
      suppressErrors: boolean = false
    ) =>
      this._http.post<IAPIResponse<IAuth>>(
        environment.API_BASE + '/identity/confirmEmail',
        { userId, confirmationToken },
        {
          params,
          context: EndpointBase.context({ suppressErrors }),
        }
      );

    public readonly ResetPassword = (
      userId: string,
      resetToken: string,
      newPassword: string,
      params?: IParams,
      suppressErrors: boolean = false
    ) =>
      this._http.post<IAPIResponse<boolean>>(
        environment.API_BASE + '/identity/resetPassword',
        { userId, resetToken, newPassword },
        {
          params,
          context: EndpointBase.context({ suppressErrors }),
        }
      );

    public readonly RefreshToken = (
      token: string | null,
      refreshToken: string | null,
      params?: IParams,
      suppressErrors: boolean = false
    ) =>
      this._http.post<IAPIResponse<IAuth>>(
        environment.API_BASE + '/identity/refreshToken',
        {
          token,
          refreshToken,
        },
        { params, context: EndpointBase.context({ suppressErrors }) }
      );

    public readonly SendResetPassword = (
      email: string,
      params?: IParams,
      suppressErrors: boolean = false
    ) => {
      if (!params) params = {};
      params['email'] = email;
      return this._http.post<IAPIResponse<boolean>>(
        environment.API_BASE + `/identity/sendEmailResetPassword/`,
        {},
        { params, context: EndpointBase.context({ suppressErrors }) }
      );
    };
  })(this._http);
}
