import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

// eslint-disable-next-line
function appInit(
  translateService: TranslateService,
  router: Router
): () => Observable<any> {
  router.routeReuseStrategy.shouldReuseRoute = function () {
    return false;
  };
  translateService.setDefaultLang('en');
  return () => translateService.use('en');
}

export const AppInitialization: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: appInit,
  multi: true,
  deps: [TranslateService, Router],
};
