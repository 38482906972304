export enum SortDirection {
  Asc = 1,
  Desc = 2,
}

export interface IPagination {
  [param: string]: string | number | boolean | undefined;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  orderBy?: number;
}
