import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/utils';
import { takeUntil } from 'rxjs';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NotificationService } from '@core/services/notification.service';
import { EmailValidator } from '@shared/validators/email.validator';

@Component({
  selector: 'gtin-callus',
  templateUrl: './callus.component.html',
  styleUrls: ['./callus.component.scss'],
})
export class CallUsComponent extends BaseComponent implements OnInit {
  environment = environment;
  form: FormGroup;
  constructor(
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private notifyService: NotificationService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, EmailValidator()]],
      message: ['', Validators.required],
    });
  }

  send(): void {
    if (this.form.invalid) {
      for (const control of Object.keys(this.form.controls)) {
        this.form.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service.execute('submit').subscribe((token: string) => {
      const req = new FormData();
      req.append('firstName', this.form.get('name')?.value);
      req.append('emailAddress', this.form.get('email')?.value);
      req.append('message', this.form.get('message')?.value);
      req.append('token', token);
      this.http
        .post<{
          success: boolean;
          data: { errorMessage: string }[];
        }>(environment.CALLUS_ENDPOINT, req)
        .pipe(takeUntil(this.destroyed()))
        .subscribe({
          next: (data) => {
            if (data.success)
              this.notifyService.notifyMsg('Message Sent!', 'Success');
            else {
              const msg = data.data.map((r) => `${r.errorMessage}`).join('');
              this.notifyService.notifyMsg(msg, 'Error');
            }
          },
          error: (err) => {
            this.notifyService.notifyMsg(err.data, 'Error');
          },
        });
    });
  }
}
