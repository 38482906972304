import { AfterViewInit, Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'gtin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'gtin';
  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    const loader = this.renderer.selectRootElement('#init-loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }
}
