<div class="header">
  <button mat-dialog-close class="closebtn btn blue">&#x2715;</button>
</div>
<div class="cert">
  <ng-container *ngIf="showCert">
    <img
      *ngIf="!data.isCertPdf"
      [src]="data.cert"
      alt="PrefixCertificate"
      (error)="showCert = false"
    />
    <pdf-viewer
      *ngIf="data.isCertPdf"
      [src]="data.cert"
      [render-text]="false"
      [original-size]="true"
      [show-all]="true"
      [autoresize]="true"
      (error)="showCert = false"
    ></pdf-viewer>
  </ng-container>
</div>
