// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  CERT_ROOT: 'https://api-stg.gtin.cloud/certificates',
  API_BASE: 'https://api-stg.gtin.cloud/api/v1',
  EMAIL_DOMAIN: 'staging.gtin.cloud',
  RECAPTCHA_SITEKEY: '6LefG7sfAAAAAHSFymLOMhrOncFbJ_KDdFXsbeSq',
  SEARCH_BASE: 'https://search-stg.gtin.cloud/v1/search',
  MVC_ROOT: 'https://bus.gtin.cloud',
  CALLUS_ENDPOINT: 'https:/bus.gtin.cloud/home/getintouch',
  TERMS_LINK: 'https://bus.gtin.cloud/terms-of-use',
  DATE_FORMAT: 'yyyy-MM-DD', //'yyyy-MM-dd'
  DATETIME_FORMAT_FULL: 'dddd - MMMM D, y - h:mmA', //'EEEE - MMMM d, y - h:mma'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
