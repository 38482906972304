import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ILoader {
  amountRunningRequest: number;
  text?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private options: ILoader = { amountRunningRequest: 0 };
  private loaderStateSubject: Subject<ILoader> = new Subject();
  private timer: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    //do nothing
  }

  getLoaderState(): Observable<ILoader> {
    return this.loaderStateSubject.asObservable();
  }

  attachOption(option: Partial<ILoader>): void {
    this.loaderStateSubject.next({ ...this.options, ...option });
  }

  inc(): void {
    this.options.amountRunningRequest++;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(
      () => this.loaderStateSubject.next(this.options),
      300
    );
  }

  dec(): void {
    if (this.options.amountRunningRequest > 0) {
      this.options.amountRunningRequest--;
      this.loaderStateSubject.next(this.options);
    } else {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = undefined;
      }
    }
  }
  reset(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
      this.options.amountRunningRequest = 0;
      this.loaderStateSubject.next(this.options);
    }
  }
}
