import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { NotificationModalComponent } from '@core/components/notification-modal/notification-modal.component';
import { IAPIException } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private dialog: MatDialog) {}
  apiErrorHandler(error: IAPIException, caption?: string) {
    let msg = error.message;

    if (error.errors && error.errors?.length > 1) {
      // check inner errors
      if (error.errors[0].errorCode == 301 && error.errors.length >= 2)
        // Validation.GeneralFailure
        msg = error.errors[1].message;
    }
    const dialog = this.dialog.open(NotificationModalComponent, {
      data: {
        header:
          caption !== undefined ? caption : 'Oops, something went wrong :-(',
        content: msg,
      },
      panelClass: ['notification-modal'],
    });
    return dialog;
  }

  showError(error: string, caption?: string) {
    const dialog = this.dialog.open(NotificationModalComponent, {
      data: {
        header:
          caption !== undefined ? caption : 'Oops, something went wrong :-(',
        content: error,
      },
      panelClass: ['notification-modal'],
      autoFocus: false,
    });
    return dialog;
  }

  notifyMsg(msg: string, caption?: string) {
    const dialog = this.dialog.open(NotificationModalComponent, {
      data: {
        header: caption !== undefined ? caption : 'Notification',
        content: msg,
      },
      panelClass: ['notification-modal'],
      autoFocus: false,
    });
    return dialog;
  }
}
