import { Component, Input } from '@angular/core';

import { BaseComponent } from '@shared/utils';

@Component({
  selector: 'gtin-custom-loader',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent extends BaseComponent {
  @Input() isVisible: boolean;
  constructor() {
    super();
  }
}
