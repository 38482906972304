import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { AuthService } from '@core/services/auth.service';
import { AppInitialization } from '@core/utils/app-init';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingOverlayModule } from '@shared/components';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule,
    CoreModule,
    LoadingOverlayModule,
    MatDialogModule,
  ],
  providers: [AppInitialization, AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {}
