import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorService } from '@core/services/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  // eslint-disable-next-line
  handleError(wrapperError: any): void {
    const errorService = this.injector.get(ErrorService);
    if (wrapperError) {
      const error = Object.prototype.hasOwnProperty.call(
        wrapperError,
        'rejection'
      )
        ? wrapperError.rejection
        : wrapperError;
      if (error instanceof HttpErrorResponse) {
        ///errorService.onHttpError(error);
      } else errorService.onAppError(error);
    }
    // /console.log(wrapperError);
  }
}
export const GLOBAL_ERROR = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler,
};
