<mat-dialog-content>
  <div class="modal-notifications">
    <div class="modal-notifications-inner">
      <h2 class="modal-notifications-header">{{ data.header }}</h2>
      <div class="modal-notifications-main">{{ data.content }}</div>
    </div>
    <div class="modal-notifications-btns">
      <button mat-dialog-close class="btn btn-reset blue">Close</button>
    </div>
  </div>
</mat-dialog-content>
