import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@shared/utils';
import { takeUntil } from 'rxjs';
import { environment } from '@env';
import { SearchService } from '@shared/data/common/services/search.service';
import { IGTINSearchResult } from '@shared/data/common/models/search.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ViewportScroller } from '@angular/common';
import { CertViewModalComponent } from '../components/modals/certview-modal/certview-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gtin-search-page',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchPageComponent extends BaseComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;

  environment = environment;
  loading: boolean = false;

  query: string | null = null;
  origQuery: string | null = null;
  searchedGTIN: string = '';
  result: IGTINSearchResult | undefined = undefined;
  cert: string | undefined;
  isCertPdf: boolean = false;
  showProdImg: boolean = true;
  showCert: boolean = true;
  errMsg: string | undefined = undefined;
  newReq: boolean = false;
  certViewDialog: MatDialogRef<CertViewModalComponent> | undefined;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private scroll: ViewportScroller,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.query = params.get('query');
      this.origQuery = this.query;
      const query = this.query;
      if (query) {
        this.loading = true;
        this.recaptchaV3Service.execute('submit').subscribe((token: string) => {
          this.result = undefined;
          this.searchService
            .SearchGTIN(query, token, {}, true)
            .pipe(takeUntil(this.destroyed()))
            .subscribe({
              next: (data) => {
                this.newReq = false;
                this.result = data;
                if (this.result) {
                  this.showCert = true;
                  this.cert =
                    environment.CERT_ROOT + this.result.certificateFile;
                  this.isCertPdf = this.cert.endsWith('.pdf');
                }
                this.searchedGTIN = query;
                this.loading = false;
              },
              error: () => {
                this.loading = false;
              },
            });
        });
      }
    });
  }

  scrollUp(): void {
    this.scroll.scrollToPosition([0, 0]);
  }

  focusSearch() {
    this.searchInput.nativeElement.focus();
  }

  showCertModal() {
    this.certViewDialog = this.dialog.open(CertViewModalComponent, {
      data: { isCertPdf: this.isCertPdf, cert: this.cert },
      panelClass: ['gtin-dialog', 'gtin-certview-panel'],
      autoFocus: false,
    });
    if (this.certViewDialog)
      this.certViewDialog
        .afterClosed()
        .pipe(takeUntil(this.destroyed()))
        .subscribe(() => (this.certViewDialog = undefined));
  }

  isValid(): boolean {
    const res =
      this.query != null && this.query.length >= 12 && this.query.length <= 14;
    const res1 = this.query != this.origQuery || !this.newReq || this.loading;
    this.errMsg = undefined;
    if (!res) {
      this.errMsg = 'Please enter 12-14 digit GTIN';
    }
    if (!res1) {
      this.errMsg = 'Please enter new GTIN to search again.';
    }
    return res && res1;
  }

  search() {
    this.newReq = true;
    if (this.isValid()) {
      this.errMsg = undefined;
      if (this.certViewDialog) this.certViewDialog.close();
      this.router.navigate(['search', this.query]);
    }
  }
}
