import { TranslateModule } from '@ngx-translate/core';
import { ClassProvider, NgModule } from '@angular/core';
import { TranslateLoaderProvider } from './utils';
import { GlobalHTTPInterceptor } from './interceptors/http-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { ErrorService } from './services/error.service';
import { LoaderService } from './services/loader.service';
import { GLOBAL_ERROR } from './interceptors/global-error-handler';
import { CommonDataModule } from '@shared/data/common';
import { ClipboardService } from './services/clipboard.service';
import { DownloadService } from './services/download.service';
import { NotificationService } from '@core/services/notification.service';
import { NotificationModalComponent } from '@core/components/notification-modal/notification-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OnlyNumberModule } from '@shared/directives/onlynumber';
import { SearchPageComponent } from './pages/search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CallUsComponent } from './pages/components/callus/callus.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from '@env';
import { LoadingOverlayModule } from '@shared/components';
import { CertViewModalComponent } from './pages/components/modals/certview-modal/certview-modal.component';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: TranslateLoaderProvider,
      useDefaultLang: false,
    }),
    CommonDataModule,
    MatDialogModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    OnlyNumberModule,
    PdfViewerModule,
    RecaptchaV3Module,
    LoadingOverlayModule,
    MatDialogModule,
  ],
  declarations: [
    NotificationModalComponent,
    MainPageComponent,
    SearchPageComponent,
    CallUsComponent,
    CertViewModalComponent,
  ],
  providers: [
    GLOBAL_ERROR,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHTTPInterceptor,
      multi: true,
    } as ClassProvider,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_SITEKEY,
    },
    AuthService,
    ErrorService,
    LoaderService,
    ClipboardService,
    DownloadService,
    NotificationService,
  ],
  exports: [MainPageComponent, SearchPageComponent],
})
export class CoreModule {}
