export class StorageHelper {
  //private constructor() {}

  static hasItem(storage: Storage, key: string): boolean {
    return storage.getItem(key) !== null;
  }

  static getItemAsString(storage: Storage, key: string): string | null {
    if (!StorageHelper.hasItem(storage, key)) {
      return null;
    }

    return storage.getItem(key);
  }

  static getItemAsNumber(storage: Storage, key: string): number | null {
    const stringValue = StorageHelper.getItemAsString(storage, key);

    if (stringValue === null) {
      return null;
    }

    return Number(stringValue);
  }

  static getItemAsBoolean(storage: Storage, key: string): boolean | null {
    const stringValue = StorageHelper.getItemAsString(storage, key);

    if (stringValue === null) {
      return null;
    }

    // Values for booleans are stored as 1 or 0
    return !!Number(stringValue);
  }

  static getItemAsObject<T>(storage: Storage, key: string): T | null {
    try {
      return JSON.parse(storage.getItem(key) ?? '{}') as T;
    } catch (_) {
      return null;
    }
  }

  static setItem(storage: Storage, key: string, value: string | null): void {
    if (value == null) {
      StorageHelper.removeItem(storage, key);
      return;
    }

    if (typeof value === 'boolean') {
      // Store boolean values as 1 or 0
      value = '' + Number(value);
    }

    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    storage.setItem(key, value);
  }

  static removeItem(storage: Storage, key: string): void {
    storage.removeItem(key);
  }
}
