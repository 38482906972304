<ng-select
  [disabled]="disabled"
  [dropdownPosition]="dropDownPosition"
  [searchable]="false"
  [items]="selectData"
  [clearable]="clearable"
  [placeholder]="placeholder"
  [(ngModel)]="selectedValue"
  [bindLabel]="label"
  [readonly]="readonly"
  [loading]="loading"
  [searchable]="searchable"
  [searchFn]="searchFn"
  [appendTo]="appendTo"
  (change)="changeValue()"
  (search)="searchValue($event)"
  (open)="open()"
>
  <ng-template ng-option-tmp let-item="item">
    <img *ngIf="item.icon" class="option-icon" [src]="item.icon" />
    <span class="ng-option-label">{{ item[label] }}</span>
  </ng-template>
</ng-select>
