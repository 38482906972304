import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { ILoader, LoaderService } from '@core/services/loader.service';

import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '@shared/utils';
import { Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'gtin-global-loader',
  templateUrl: './global-loading-overlay.component.html',
  styleUrls: ['./global-loading-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalLoadingOverlayComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  text: string;
  ajaxRequest: boolean;
  routing: boolean;
  private subscription: Subscription;
  constructor(
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private router: Router
  ) {
    super();
    this.text = this.translateService.instant('common.loading');
  }

  ngOnInit(): void {
    this.subscription = this.loaderService
      .getLoaderState()
      .pipe(takeUntil(this.destroyed()))
      .subscribe((loader: ILoader) => {
        this.ajaxRequest = loader.amountRunningRequest > 0;
        this.cdRef.detectChanges();
      });
    this.router.events.pipe(takeUntil(this.destroyed())).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.routing = true;
        this.cdRef.detectChanges();
      } else if (event instanceof NavigationEnd) {
        this.routing = false;
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
