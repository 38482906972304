import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@shared/utils';

type DialogData = { isCertPdf: boolean; cert: string };

@Component({
  selector: 'gtin-certview',
  templateUrl: './certview-modal.component.html',
  styleUrls: ['./certview-modal.component.scss'],
})
export class CertViewModalComponent extends BaseComponent {
  showCert: boolean = true;
  constructor(
    private dialogRef: MatDialogRef<CertViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super();
  }
}
