import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

interface IData {
  header: string;
  content: string;
}

@Component({
  selector: 'gtin-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IData
  ) {
    ('');
  }

  ngOnInit() {
    ('');
  }
}
