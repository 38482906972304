import { StorageHelper } from './storage-helper.util';

export class LocalPreferences {
  //private constructor() {}

  static get accountId(): number | null {
    return StorageHelper.getItemAsNumber(localStorage, 'accountId');
  }

  static set accountId(accId: number | null) {
    StorageHelper.setItem(localStorage, 'accountId', '' + accId);
  }

  static get token(): string | null {
    return StorageHelper.getItemAsString(localStorage, 'token');
  }

  static set token(token: string | null) {
    StorageHelper.setItem(localStorage, 'token', token);
  }

  static get refreshToken(): string | null {
    return StorageHelper.getItemAsString(localStorage, 'refreshToken');
  }

  static set refreshToken(refreshToken: string | null) {
    StorageHelper.setItem(localStorage, 'refreshToken', refreshToken);
  }
}
