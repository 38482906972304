import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NgSelectModule],
  exports: [SelectComponent],
})
export class SelectModule {}
